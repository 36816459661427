import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LocalStorageService } from 'app/core/service/localstorage/local-storage.service';
import { ReportRequest, ReportRequestResponse } from 'app/core/service/report/report.model';
import { BASE_URL } from 'environments/environment';
import { BehaviorSubject, map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DownloadsService {

    private timezone = null
    private _downloads: BehaviorSubject<ReportRequest[]> = new BehaviorSubject<ReportRequest[]>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, public afAuth: AngularFireAuth,
        public _localStorage: LocalStorageService, private firestore: AngularFirestore) {
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let context = this
        afAuth.onAuthStateChanged(function (user) {
            if (user) {
                context.getDownloads(user)
            }
            // else {
            //     // No user is signed in. 
            //     afAuth.signInWithCustomToken(_localStorage.firebaseToken)
            // }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for downloads
     */
    get downloads$(): Observable<ReportRequest[]> {
        return this._downloads.asObservable();
    }

    getDownloads(user) {
        this.firestore.collection('pdf-export-downloads', ref => ref.where('user_uid', '==', user.uid).orderBy('created_at', 'desc')).valueChanges().subscribe(data => {
            this._downloads.next(data as ReportRequest[])
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    deleteReportRequest(requestId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}report/pdf/${requestId}/`)
    }

    generateExportRequest(filterData): Observable<any> {
        filterData['user_timezone'] = this.timezone;
        filterData['event_list_required'] = true
        return this._httpClient.post<any>(`${BASE_URL}report/pdf/`, filterData)
    }

}
